.offer {
	margin-bottom: 85px;
	&_content {
		padding: 58px 36px 80px;
		background: url('../img/offer_bg.jpg') no-repeat;
	}
	&_heading {
		font-size: 34px;
		font-weight: 700;
		max-width: 430px;
		text-transform: uppercase;
		margin-bottom: 20px;
		line-height: 1.4;
		transition-delay: .5s;
	}
	&_list {
		margin-left: 10px;
		font-size: 22px;
		line-height: 1.6;
		list-style-type: none;
		margin-bottom: 34px;
		transition-delay: 1s;

		li {
			padding-left: 23px;
			position: relative;
			text-transform: uppercase;
			margin-bottom: 2px;

			&:before {
				content: '';
				display: block;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0,-50%);
				background:#ba2322;
			}
		}
	}
	.btn {
		display: block;
		margin-left: 5px;
		font-size: 19px;
		max-width: 270px;
		padding: 14px 10px 12px;
		font-weight: 500;
		font-family: 'Graphik', sans-serif;
	}
}

.partnership {
	display: flex;
	flex-wrap: wrap;
	padding-top: 0px;
	padding-bottom: 30px;
	h2 {
		width: 100%;
		text-align: center;
		font-size: 28px;
		margin-bottom: 83px;
		font-family: 'Graphik', sans-serif;
		text-transform: uppercase;
		transition-delay: .5s;

	}
	&_wrap {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 75px;
	}
	&_item {
		margin: 0 15px;
		background: #fff;
		position: relative;
		width: 100%;
		padding: 90px 15px 35px 15px;
		color: #000;
		&-icon {
			display: flex;
			align-items:center;
			// justify-content: center;
			padding-left: 12px;
			width: 90px;
			height: 83px;
			background: url('../img/partneship_icon-bg.png') right center no-repeat;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%,-20px);
		}
		&-name {
			font-size: 18px;
			text-transform: uppercase;
			margin-bottom: 25px;
			text-align: center;
		}
		&-list {
			font-size: 15px;
			list-style: none;
			line-height: 1.8;
			li {
				margin-bottom: 0px;
				&:last-of-type {
					margin-bottom: 0;
				}
				&:before {
					content: '•';
				}
			}
		}

		&:nth-of-type(1) {
			transition-delay: .75s;
		}
		&:nth-of-type(2) {
			transition-delay: 1s;
		}
		&:nth-of-type(3) {
			transition-delay: 1.25s;
		}
	}
	&_form {
		max-width: 980px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.input {
			width: 100%;
			&_wrap {
				width:46%;
				margin-bottom: 12px;
				&.wide {
					width: 100%;
				}
			}
			&_label {
				margin-bottom: 10px;
				display: block;
			}
		}
		.policy {
			width: 100%;
			span{
				display: block;
				margin: 15px auto 30px;
				max-width: 740px;
				text-align: center;
			}
		}
		.btn {
			margin: 0 auto;
			max-width: 265px;
		}
	}
}

.brands {
	padding-top: 40px;
	padding-bottom: 80px;
	h2 {
		font-size: 28px;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 40px;
	}
	&_wrap {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-auto-rows: auto;
		grid-gap: 26px 22px;
		max-width: 910px;
		margin: 0 auto;
	}
	&_item {
		display: flex;
		align-items: center;
		justify-content: center;
 		box-shadow: 0px 7px 12.35px 0.65px rgba(0, 0, 1, 0.03);
		background: #fff;
	}
}

.contacts {
	position: relative;
	margin-bottom: 90px;
	.map {
		z-index: 5;
		transition-delay: .5s;
	}
	&_block {
		position: absolute;
		bottom: 0;
		right: 50%;
		padding:35px 35px 35px;
		background:#fff;
		width: 100%;
		max-width: 355px;
  		box-shadow: 0px 16px 21.6px 2.4px rgba(0, 0, 0, 0.12);
		transition-delay: 1s;
		h4 {
			font-size: 19px;
			color: #ba2322;
			margin-bottom: 25px;
		}
		ul {
			list-style-type: none;
			line-height: 1.5;
			font-size: 15px;

			&, 
			a {
				color:#000;
			}
			a:hover {
				text-decoration: underline;
			}
			li:last-of-type {
				margin-top: 30px;
			}
		}
	}
}

.start_partnership {
	display: flex;
	margin-bottom: 80px;
	&-text {
		position: relative;
		width: 100%;
		max-width: 462px;
		padding-right: 17px;
		padding-bottom: 60px;
		h2 {
			font-size: 36px;
			text-transform: uppercase;
			margin-bottom: 30px;
			transition-delay:.5s;
		}
		p {
			font-size: 14px;
			margin-bottom: 18px;
			line-height: 1.8;
			transition-delay:1s;
			&:last-of-type {
				margin-bottom: 45px;
			}
		}
		.btn_wrap {
			transition-delay:1.5s;
		}
		.btn {
			margin-left: 0;
			max-width: 265px;	
			color: #fff;
			&:hover {
				color: var(--red);
			}
		}
		&:before {
			content:'';
			display: block;
			height: 100%;
			width: 2px;
			background:  #fff;
			box-shadow: 0px 9px 21.33px 5.67px rgba(0, 0, 1, 0.06);	
			position: absolute;
			top: 0;
			right: 0;
			transition-delay:1.5s;
		}
	}
	&-benefits {
		padding: 12px 30px 20px;
		display: grid;
		grid-template-columns: 1.12fr 1fr;
		grid-gap: 23px 10px;
		// max-width: 0px;
		width: 100%;
		transition-delay:2s;

	}
	&-item {
		padding-left: 63px;
		position: relative;
		h5 {
			padding-top: 7px;
			margin-bottom: 12px;
			font-size: 13px;
			text-transform: uppercase;
		}
		p{
			font-size: 13px;
			line-height: 1.5;
		}
	}
	&-icon {
		position: absolute;
		top: 0;
		left:0;
		display: block;
	}
}

.advantages {
	&_header {
		padding: 6px 30px 4px;
		border-left: 8px solid #000;
		text-transform: uppercase;
		color: #999;
		font-size: 22px;
		margin-left: 55px;
		transition-delay:.5s;
		h2 {
			font-size: 28px;
			color:#000;
			margin-bottom: 8px;
		}
	}

	&_circle {
		margin: -30px auto 150px;
		max-width: 490px;
		position: relative;
		transition-delay:1s;
		&-steps {
			position: absolute;
			top: calc(50% + 9px);
			left:calc(50% - 3px);
			transform: translate(-50%,-50%) rotate(-135deg);

			g {
				transform-origin: center;
				&:nth-of-type(1) {
					transform: rotate(45deg);
					// opacity: .1;
					transition-delay: 1s;
				}
				&:nth-of-type(2) {
					transform: rotate(90deg);
					// opacity: .3;
					transition-delay: 1.5s;
				}
				&:nth-of-type(3) {
					transform: rotate(135deg);
					// opacity: .5;
					transition-delay: 2s;
				}	
				&:nth-of-type(4) {
					// opacity: .7;
					transform: rotate(180deg);
					transition-delay: 2.5s;
				}	
				&:nth-of-type(5) {
					// opacity: .8;
					transform: rotate(225deg);
					transition-delay: 3s;
				}
				&:nth-of-type(6) {
					// opacity: .9;
					transform: rotate(270deg);
					transition-delay: 3.5s;
				}	

			}
		}
		.logo {
			font-size: 42px;
			font-weight: 700;
			position: absolute;
			right: 50%;
			bottom: 60%;
			white-space: nowrap;
		}
		.advantages_img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
		&-list {
			counter-reset: circleCounter;
			list-style: none;
			li {
				font-weight: 500;
				color: #000;
				font-size: 16px;
				position: absolute;
				width: 100vw;
				&:before {
					counter-increment: circleCounter;
					content: '0' counter(circleCounter);	
					font-size: 58px;
					color: #ccc;
					display: block;
					font-weight: 500;
					margin-bottom: 0px;				
				}
				&:after {
					content:'';
					display: block;
					position: absolute;
					border:3px dotted #000;
				}
				&:nth-of-type(1) {
					top: -57px;
					left: 87%;
					max-width: 268px;
					transition-delay: 1s;

					&:after {
						left: -25px;
						top:85%;
						transform: translate(-100%,-100%);
						border-right: none;
						border-bottom: 	none;
						width: 80px;
						height: 20px;
					}
				}
				&:nth-of-type(2) {
					top: 100px;
					left: calc(100% + 40px);
					max-width: 210px;
					transition-delay: 1.5s;
					&:after {
						left: -20px;
						top:100%;
						transform: translate(-100%,-100%);
						border-right: none;
						border-bottom: 	none;
						border-left: none;
						width: 70px;
						height: 20px;
					}
				}
				&:nth-of-type(3) {
					top: 290px;
					left: calc(100% + 40px);
					max-width: 210px;
					transition-delay: 2s;
					&:after {
						left: -20px;
						top:92%;
						transform: translate(-100%,-100%);
						border-right: none;
						border-bottom: 	none;
						border-left: none;
						width: 80px;
						height: 20px;
					}
				}
				&:nth-of-type(4) {
					top: 432px;
					left: calc(100% - 112px);
					max-width: 215px;
					transition-delay: 2.5s;
					&:after {
						left: -20px;
						top:65%;
						transform: translate(-100%,-100%);
						border-right: none;
						border-top: none;
						width: 80px;
						height: 20px;
					}
				}
				&:nth-of-type(5) {
					top: 415px;
					max-width: 265px;
					text-align: right;
					left: unset;
					right: 88%;
					transition-delay: 3s;
					&:after {
						content:'';
						display: block;
						position: absolute;
						border:3px dotted #000;
						width: 75px;
						height: 25px;
						right: -20px;
						top:70%;
						border-top: none;
						border-left: none;
						transform: translate(100%,-50%);
					}
				}
				&:nth-of-type(6) {
					top: 270px;
					right: calc(100% + 37px);
					max-width: 220px;
					text-align: right;
					transition-delay: 3.5s;
					&:after {
						content:'';
						display: block;
						position: absolute;
						border:3px dotted #000;
						width: 50px;
						height: 25px;
						right: -20px;
						top:60%;
						border-top: none;
						border-left: none;
						border-right: none;
						transform: translate(100%,-50%);
					}
				}
			}
		}
		&-icons {
			img {
				position: absolute;
				
				&:nth-of-type(1){
					top: 80px;
					right: 160px;	
				}
				&:nth-of-type(2){
					top: 160px;
					right: 70px;	
				}
				&:nth-of-type(3){
					top: 290px;
					right: 70px;
				}
				&:nth-of-type(4){
					top: 370px;
					right: 150px;
				}
				&:nth-of-type(5){
					top: 370px;
					left: 160px;
				}
				&:nth-of-type(6){
					top: 295px;
					left: 74px;
				}
			}
		}
	}
}


@media screen and (max-width: 1200px) {
	.offer {
		&_content {
			padding: 60px 10px 90px;
			background-position: -175px 0;
		}
	}
	.advantages {
		&_header {
			margin-left: 0;
		}
		&_circle {
			// margin-top: 0;
			transform: scale(.75);
		}
	}
	.partnership {
		&_item {
			margin: 0 5px;
		}
		&_form .input_wrap {
			width: 49%;
		}
	}
	.brands_wrap {
		grid-template-columns: repeat(3, 1fr);
	}
	.start_partnership {
		flex-direction: column;
		&-text {
			padding-right: 0;
			max-width: 100%;
			padding-bottom: 30px;
			margin-bottom: 30px;
			&:before {
				width: 100%;
				height: 3px;
				left: 0;
				top:100%;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.login_btns {
		flex-direction:column;
		a {
			margin-left: 0;
			margin-bottom: 15px;
		}
	}
	.offer {
		&_content {
			background-position: -100px 0; 
			padding: 15px;
		}
		&_heading {
			margin: 0 0 15px;
			font-size: 24px;
		}
		&_list {
			font-size: 15px;
		}
	}
	.advantages {
		&_header {
			font-size: 14px;
			margin-bottom: 20px;
			h2 {
				font-size: 20px;
			}
		}
		&_circle {
			transform: scale(1);
			margin: 0 0 50px;
			img,svg {
				display: none;
			}
			.logo {
				display: none;
			}
			&-list {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 25px;
				li {
					position: static;
					top: unset;
					left: unset;
					right: unset;
					bottom: unset;
					text-align: left !important;
					&:after {
						display: none !important;
					}
				}
			}
		}
	}
	.partnership {
		&_wrap {
			flex-direction: column;
			margin-bottom: 0;
		}
		&_item {
			margin: 0 0 50px;
		}
		&_form .input_wrap {
			width: 100%;
		}
	}
	.brands_wrap {
		grid-template-columns: 1fr 1fr
	}
	.contacts_block {
		position: static;
		max-width: 100%;
	}
	.start_partnership-benefits {
		grid-template-columns:  1fr;
	}
}

@media screen and (max-width: 567px) {
	.offer_heading {
		font-size: 20px;
	}
	.advantages_circle-list {
		display: block;
		li {
			max-width: 100% !important;
			margin-bottom: 20px;
			br {
				display: none;
			}
			&:before {
				font-size: 36px;
			}
		}
	}
	.start_partnership-benefits {
		padding: 0;
	}
	.partnership,
	.brands,
	.start_partnership-text {
		h2 {
			font-size: 20px;
		}
	}
} 

.fadeIn {
	transition-duration: 1s;
	opacity: 0;
	pointer-events: none;

	.animated &{
		opacity: 1;
		pointer-events: unset;
	}
}