// modals 
	.modal {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%) scale(0);
		width: 100%;
		height: 100%;
		z-index: 99;
		background-color: rgba(0,0,0,.6);
		z-index: 99;
		padding: 20px;
		overflow:auto;
		display: flex;
		&.active {
			transform: translate(-50%,-50%) scale(1);
			.modal_content {
				transform: scale(1)
			}
		}
	}
	.modal_content {
		width: 90%;
		max-width: 600px;
		background-color: #fff;
		z-index: 100;
		padding: 20px 35px;
		font-size: 14px;
		box-shadow: 0px 18px 30px rgba(136, 178, 222, 0.13);
		transform: scale(0);
		transition: .5s;
		margin: auto;
		h4 {
			font-size: 24px;
			font-weight: 700;
			margin-bottom: 10px;
		}
		p {
			font-size: 15px;
			line-height: 1.5;
		}
		.input_wrap {
			margin: 10px 0;
		}

		input {
			&[type='file'] {
				&+ .input_label {
					margin-left: 0;
					padding-left: 40px;
					&:before {
						left: 10px;
						transform:translate(0,-50%);
					}
				}
			}
		}
		.input {
			width: 100%;
		}
		.btn {
			margin-top: 25px;
		}
	}
	.overlay {
		overflow: hidden;
	}
	.close {
		position: absolute;
		top: 20px;
		right: 15px;
		width: 25px;
		height: 25px;
		font-size: 25px;
		border: none;
		cursor: pointer;
		background: none;
		// background: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.7269 21.4087L12.8186 11.5004L22.7268 1.59223C23.0909 1.22813 23.0909 0.637727 22.7268 0.273591C22.3627 -0.0905458 21.7723 -0.0905458 21.4082 0.273591L11.5 10.1818L1.59174 0.273591C1.22765 -0.0905458 0.637238 -0.0905458 0.273102 0.273591C-0.091034 0.637682 -0.091034 1.22809 0.273102 1.59223L10.1813 11.5004L0.273102 21.4087C-0.091034 21.7728 -0.091034 22.3632 0.273102 22.7273C0.637193 23.0914 1.2276 23.0915 1.59174 22.7273L11.5 12.819L21.4083 22.7273C21.7724 23.0914 22.3628 23.0915 22.7269 22.7273C23.091 22.3632 23.091 21.7728 22.7269 21.4087Z' fill='%2395A0B4'/%3E%3C/svg%3E%0A") center no-repeat;
	}
	.modal_btn {
		* {
			pointer-events:none;
		}
	}
		
	@media screen and (max-width: 510px) {
		.wrapper {
			overflow: hidden;
		}
		.modal {
			padding: 10px;
		}
		.modal_content {
			padding: 20px 10px;
			max-width: 100% !important;
			width: 100%;
			margin-left: 0;
			margin-right: 0;

		}
	}
// modals 