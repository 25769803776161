.sale_img {
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
	img {
		width: 100%;
	}
}

.lk {
	&_header {
		margin-bottom: 30px;

		h2 {
			font-size: 24px;
			font-weight: 500;
			margin-bottom: 30px;
		}
	}
	&_nav {
		display: flex;
		list-style: none;
		a {
			display: block;
			padding: 14px 11px;
			background: #f5f5f5;
			border:1px solid #d0d0d0;
			margin-right: 13px;
			color: #000;
			&:hover {
				box-shadow: 0px 3px 7px 1px rgba(0,0,0,.15);
			}
			&.active{
				box-shadow: 0px 3px 7px 1px rgba(0,0,0,.15);
				background:#fff;
			}
		}
		&-toggler {
			align-items: center;
			font-size: 20px;
			padding-left: 10px;
			background: #fff;
			border:1px solid #ccc;
			width: 100%;
			justify-content: space-between;
			display: none;
			&.active {
				img {
					transform: scale(1,-1);
				}
			}
			img {
				margin-left: 10px;
			}
		}
	}
}

.table-orders {
	margin-bottom: 50px;
	.table_row {
		grid-template-columns: 80px 1.25fr .75fr 1fr 1.25fr 1.25fr 2fr;
		grid-gap: 20px;
		padding: 25px 15px;
		text-align: center;
		&.table_header {
			font-size: 18px;
			font-weight: 500;
			margin-bottom: 0;
			text-align: center;
			.table_cell:nth-of-type(3) {
				text-align: center;
			}	
		}
		.table_cell {
			&:nth-of-type(3) {
				text-align: left;
			}
			&:last-of-type {
				display: flex;
				justify-content: space-around;
			}
		}
	}
}

.fuct_btn {
	background: none;
	border: none;
	position: relative;
	svg {
		fill: #999999;
		transition: .5s;
		pointer-events: none;
	}
	&:before {
		content:attr(title);
		position: absolute;
		bottom: 115%;
		white-space: nowrap;
		padding: 5px 15px;
		background:var(--red);
		color:#fff;
		text-align: center;
		font-size: 13px;
		font-weight: 500;
		display: none;
		left: -25px;
	}
	&:after {
		content:'';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 6px 5px 0 5px;
		border-color: var(--red) transparent transparent transparent;
		bottom: 115%;
		left:0px;
		position: absolute;
		transform: translate(0,95%);
		display: none;
	}
	&:hover {
		svg {
			fill:var(--red);
		}
		&:before,
		&:after {
			display: block;
		}
	}
	&:nth-of-type(3),
	&:nth-of-type(4) {
		&:before {
			left:unset;
			right: -25px;
		}
		&:after {
			left:unset;
			right: 0;
		}
	}
}

.pagination {
	display: flex;
	list-style: none;
	justify-content: flex-end;
	margin-bottom: 50px;
	li {
		a {
			box-shadow: 0px 1px 6.51px 0.49px rgba(0, 0, 0, 0.06);
			border-radius: 2px;
			width: 26px;
			height: 26px;
			line-height: 26px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			margin: 0 5px;
			background: #fff;
			&.active {
				background: #d0cfcf;
				color:#fff;
			}
			&:hover {
				color: var(--red);
			}
		}
		&:last-of-type,
		&:first-of-type {
			a {
				box-shadow: unset;
				color:var(--red);
				font-size: 16px;
				background: unset;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.table-orders {
		margin-bottom: 50px;
		.table_row {
			grid-gap:30px 10px;
			padding:25px 5px;
			grid-template-columns: 80px 1.25fr 1fr 1fr 1fr 2fr;
		}
		.table_cell:last-of-type {
			grid-column: 1 / 7;
		}
	}
}
@media screen and (max-width: 767px) {
	.lk_nav {
		flex-direction: column;
		display: none;
		&.active {
			display: flex;
		}
		a {
			width: 100%;
			margin-right: 0;
		}
		&-toggler {
			display: flex;
		}
	}
	.table-orders {
		margin-bottom: 50px;
		.table_row {
			grid-gap:30px 10px;
			padding:25px 5px;
			grid-template-columns: 1fr;
			text-align: left;
			&.table_header {
				display: none;
			}
		}

		.table_cell {
			border-bottom: 1px dashed var(--red);
			padding-bottom: 10px;

			&:before {
				display: block;
				content:attr(title);
				font-size: 12px;
				font-weight: 700;
				margin-bottom: 10px;
			}
			&:last-of-type {
				grid-column: 1 / 2;
				border-bottom: none;
				padding-bottom: 0;
				&:before {
					display: none;
				}
			}
		}
	}
	.pagination {
		justify-content: center;
		li {
			&:nth-of-type(7),
			&:nth-of-type(6), {
				display: none;
			}
		}
	}

}