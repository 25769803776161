.step1 {
	transition-delay:.5s;
}
.step2 {
	transition-delay:1s;
}
.step3 {
	transition-delay:1.5s;
}
.step4 {
	transition-delay:2s;
}
.step5 {
	transition-delay:2.5s;
}
.step6 {
	transition-delay:3s;
}
.step7 {
	transition-delay:3.5s;
}
.step8 {
	transition-delay:4s;
}
.step9 {
	transition-delay:4.5s;
}
.step10 {
	transition-delay:5s;
}
.step11 {
	transition-delay:5.5s;
}
.step12 {
	transition-delay:6s;
}
.step13 {
	transition-delay:6.5s;
}
.step14 {
	transition-delay:7s;
}

.fadeUp {
	transform: translate(0,40px);
	opacity: 0;
	transition-duration: 1s;
}

.fadeDown {
	transform: translate(0,-40px);
	opacity: 0;
	transition-duration: 1s;
}

.fadeLeft {
	transform: translate(40px,0px);
	opacity: 0;
	transition-duration: 1s;
}

.rollLeft {
	transform: scale(0,1);
	overflow: visible;
	opacity: 0;
	transform-origin: 0 0;
	transition-duration: 1s;

}


.rollTop {
	transform: scale(1,0);
	overflow: visible;
	opacity: 0;
	transform-origin: 0 0;
	transition-duration: 1s;

}


.section {
	transition-duration: 1s;
	opacity: 0;
	&.animated {
		opacity: 1;
		.fadeUp,.fadeDown,
		.fadeLeft {
			transform: translate(0,0);
			opacity: 1;
		}
		.rollLeft {
			transform: scale(1,1);
			opacity: 1;
		}
		.rollTop {
			transform: scale(1,1);
			opacity: 1;
		}
	}
	&.animation_ends {
		.step1,.step2,.step3,.step4,.step5,
		.step6,.step7,.step8,.step9,.step10,
		.step11,.step12,.step13,.step14 {
			transition-delay: 0s !important;	
		}
	}
}