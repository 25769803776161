.header {
	display: flex;
	align-items: center;
	padding-top: 60px;
	padding-bottom: 40px;
	margin-bottom: 30px;
	a {
		color: #000;
		position: relative;
		display: block;
		white-space: nowrap;
		font-size: 15px;
		margin-left: 40px;
		&:not(.logo):hover {
			color: var(--red);
			text-decoration: underline;
		}
		&.logo {
			margin-left: 0;
			margin-right: auto;
			font-size: 27px;
			font-weight: 900;
		}
		&[href*='tel'] {
			padding-left: 30px;
			&:before {
				content:'';
				display: block;
				width: 23px;
				height: 23px;
				position: absolute;
				top:50%;
				left:0;
				transform: translate(0,-50%);
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='172 -216.8 473.8 473.8' fill='%23ba2322'%3E%3Cpath d='M546.5,76.7c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4 c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75C334.5,8,326.1-5.3,320-18.8c8.2-7.5,15.8-15.3,23.2-22.8 c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6 c-9.7-9.6-21.3-14.7-33.5-14.7c-12.2,0-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5 c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26 c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9 c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L546.5,76.7z M582.3,182C582.2,182,582.2,182.1,582.3,182 c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1 c-29.7-1.9-57.3-13.5-78-23.4C374,179.2,324.3,140.3,283,91c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6 c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3 c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6 c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9 c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3 c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5 c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1C592.5,160.9,592.5,171.4,582.3,182z'/%3E%3C/svg%3E") center no-repeat;
				background-size: contain;
				
			}
		}
		&[href*='mailto:'] {
			padding-left: 30px;
			&:before {
				content:'';
				display: block;
				width: 23px;
				height: 23px;
				position: absolute;
				top:50%;
				left:0;
				transform: translate(0,-50%);
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 483.3 483.3' fill='%23ba2322'%3E%3Cpath d='M424.3,57.75H59.1c-32.6,0-59.1,26.5-59.1,59.1v249.6c0,32.6,26.5,59.1,59.1,59.1h365.1c32.6,0,59.1-26.5,59.1-59.1 v-249.5C483.4,84.35,456.9,57.75,424.3,57.75z M456.4,366.45c0,17.7-14.4,32.1-32.1,32.1H59.1c-17.7,0-32.1-14.4-32.1-32.1v-249.5 c0-17.7,14.4-32.1,32.1-32.1h365.1c17.7,0,32.1,14.4,32.1,32.1v249.5H456.4z'/%3E%3Cpath d='M304.8,238.55l118.2-106c5.5-5,6-13.5,1-19.1c-5-5.5-13.5-6-19.1-1l-163,146.3l-31.8-28.4c-0.1-0.1-0.2-0.2-0.2-0.3 c-0.7-0.7-1.4-1.3-2.2-1.9L78.3,112.35c-5.6-5-14.1-4.5-19.1,1.1c-5,5.6-4.5,14.1,1.1,19.1l119.6,106.9L60.8,350.95 c-5.4,5.1-5.7,13.6-0.6,19.1c2.7,2.8,6.3,4.3,9.9,4.3c3.3,0,6.6-1.2,9.2-3.6l120.9-113.1l32.8,29.3c2.6,2.3,5.8,3.4,9,3.4 c3.2,0,6.5-1.2,9-3.5l33.7-30.2l120.2,114.2c2.6,2.5,6,3.7,9.3,3.7c3.6,0,7.1-1.4,9.8-4.2c5.1-5.4,4.9-14-0.5-19.1L304.8,238.55z' /%3E%3C/svg%3E%0A") center no-repeat;
				background-size: contain;			
			}
		}
	}
	&-landing {
		padding-top:30px;
		padding-bottom: 0px;
	}
}
nav {
	display: flex;
	align-items:center;
	a {
		position: relative;
		&:before {
			content:'';
			display: none;
			width: 95%;
			height: 3px;
			background: var(--red);
			position: absolute;
			bottom: -6px;
			left:50%;
			transform: translate(-50%,0);
		}
		&.active:before {
			display: block;
		}
	}
}
.nav {
	display: flex;
	color: #000;
	width: 100%;
	list-style: none;
}
.nav_toggler {
	width: 21px;
	height: 25px;
	border: none;
	background:transparent;
	position: relative;
	transition:0.5s;
	display: none;
	span {
		display: block;
		width: 18px;
		height: 2px;
		position: absolute;
		top: 50%;
		left: 0%;
		transform: translate(0%,-50%);
		background:var(--red);
		z-index: 5;
		pointer-events:none;
		transition:0.5s;
		
		&:first-of-type {
			top: calc(50% - 7px);
		}
		&:last-of-type {
			top: calc(50% + 7px);
		}
		&:nth-of-type(2) {
			left: 0;
			transform: translate(0,-50%)
		}
	}
	&.active {
		span {
			top: 50% !important;
			left: 50% !important;
			&:nth-of-type(2),
			&:nth-of-type(1) {
				transform: translate(-50%,-50%) rotate(-225deg);
			}
			&:last-of-type {
				transform: translate(-50%,-50%) rotate(225deg)
			}
		}
	}
}
.header_contacts {
	display: flex;
	&:only-child {
		margin-top: 0;
	}
}
.login_btns {
	display: flex;
	align-items: center;
	a {
		color: #fff;
		margin-left: 26px;
		max-width: unset;
		font-weight: 500;
		text-transform: none;
		
	}
}
@media screen and (max-width: 1200px) {
	.header {
		padding-top: 30px;
		padding-bottom: 20px;
		justify-content: space-between;
		a {
			margin-left: 20px;
 			&.logo {
 				font-size: 24px;	
			}
		}
		&_contacts {
			margin-top: 15px;
		}
	}
	nav {
		flex-direction: column;
		align-items: flex-end;
	}
}

@media screen and (max-width: 767px) {
	.nav_toggler {
		display: block;
	}
	nav {
		position: absolute;
		top: -250%;
		right: calc(50vw - 250px);
		background:#fff;
		padding: 25px 25px 15px;
		box-shadow: 0 0 10px 5px rgba(0,0,0,.3);
		transition: 0.5s;
		z-index: 99;
		&.active {
			top:77px;
		}
	}
	.nav,
	.header_contacts {
		flex-direction: column;
		align-items: flex-start;
		margin:0;
		a {
			margin-left: 0;
			margin-bottom: 30px;
		}
	}
	.header_contacts a:last-of-type {
		margin-bottom: 5px;
	}
}

@media screen and (max-width: 515px) {
	nav {
		right: calc(50vw - 185px);
	}
}

@media screen and (max-width: 400px) {
	.header {
		a {
			font-size: 12px;
			&.logo {
				font-size: 20px;
			}
		}
	}
	nav {
		right: calc(50vw - 170px);
	}
}
@media screen and (max-width: 355px) {
	nav {
		right: calc(50vw - 150px);
	}
}
