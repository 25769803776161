/*global*/
 	html {
 		scroll-behavior: smooth;
 	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		outline: none;

	}
	body {
		font-family: 'Graphik', sans-serif;
		background: #fbfbfb;
		color:#000;
		font-size: 16px;
	}
	button {
		cursor: pointer;
	}
	button, input, textarea {
		color: inherit;
		transition: 0.5s;
	}
	h1,h2,h3,h4,h5,h6,p {
		font-size: inherit;
	}
	a {
		transition: 0.5s;
		text-decoration: none;
	} 
	a:visited, a:focus {
		color: inherit;
	}
	address {
		font-style: normal;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	    -webkit-appearance: none;
	}
	input[type='number'] {
	    -moz-appearance: textfield;
	}
	.center {
		padding-left: calc(50vw - 580px);
		padding-right: calc(50vw - 580px);
	}
	.visually_hidden {
		position: absolute;
		width: 1px;
		height: 1px;
		overflow: hidden;
		clip: rect(0 0 0 0);
	}
	img,svg {
		display: block;
		max-width: 100%;
	}

	:root {
		--red:#ba2322;
	}
	.red {
		color:var(--red);
	}
/*global*/

// login
	.login_form {
		width: 100%;
		max-width: 440px;
		padding: 40px 12px 50px;
		background:#fff;
		box-shadow: 0px 5px 19.92px 4.08px rgba(0, 0, 0, 0.06);
		margin:0 auto;
		p {
			margin: 15px 0;
			padding-left: 100px;
		}
		.btn {
			margin: 30px auto;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		&_wrap {
			padding-bottom: 40px;
		}
	}
	.login_heading {
		font-size: 28px;
		font-weight: 500;
		text-align: center;
		margin-bottom: 45px;
	}
	.input {
		border:1px solid rgb(204, 204, 204);
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 1px 6.51px 0.49px rgba(0, 0, 0, 0.06);
		padding:15px 20px;	
		width: calc(100% - 100px);
		resize: none;
		border-radius: 0px;
		&_wrap {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			&-error {
				.input_error {
					display: block;
				}
				.input {
					border-color:var(--red);
				}
			}
		}
		&_label {
			min-width: 98px;
		}
		&_text {
			padding-left: 100px;
			width: 100%;
			margin: 5px 0;
			font-size: 14px;
		}
		&_error {
			color: var(--red);
			width: 100%;
			margin: 5px 0;
			padding-left: 100px;
			display: none;
			font-size: 14px;
		}
	}
	input[type='file'] {
		&+.input_label {
			padding: 10px;
			width: 100%;
			max-width: 180px;
			margin-left: auto;
			font-size: 14px;
			font-weight: 500;
			background:#fff;
			border:1px solid rgb(246, 246, 246);
			box-shadow: 0px 9px 6.51px 0.49px rgba(0, 0, 0, 0.06);
			text-align: center;
			position: relative;
			cursor: pointer;
			transition: .5s;
			&:before {
				content:'';
				position: absolute;
				top: 50%;
				left: -15px;
				display: block;
				width: 25px;
				height: 25px;
				background: url("data:image/svg+xml,%3Csvg height='30' viewBox='0 0 30 30' width='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.243.008c-.826.05-1.62.31-2.322.708-.7.396-1.29.91-1.847 1.403L3.28 16.972c-.71.628-1.443 1.292-2.035 2.114-.592.823-1.02 1.78-1.176 2.8-.158 1.02-.042 2.068.302 3.03.342.962.898 1.823 1.56 2.567.66.743 1.448 1.395 2.36 1.85.913.454 1.942.666 2.966.666 1.03 0 2.027-.34 2.915-.823.888-.484 1.637-1.126 2.35-1.75l14.3-12.534c.502-.44-.158-1.194-.66-.754l-14.3 12.535c-.704.617-1.4 1.207-2.166 1.624-.766.416-1.618.7-2.47.7-.847 0-1.718-.178-2.49-.562-.774-.386-1.467-.954-2.058-1.618-.592-.666-1.075-1.422-1.366-2.238-.29-.813-.386-1.698-.256-2.542.13-.844.488-1.66.998-2.37.51-.708 1.185-1.325 1.888-1.947l16.79-14.856c.552-.487 1.093-.948 1.68-1.28.586-.33 1.238-.54 1.89-.58.65-.04 1.32.093 1.916.37.6.28 1.14.706 1.587 1.217.447.51.798 1.1 1 1.727.198.628.248 1.304.13 1.946-.12.64-.407 1.26-.804 1.803-.397.543-.917 1.025-1.463 1.516L9.763 24.766c-.382.343-.75.66-1.14.877-.392.22-.825.35-1.254.37-.43.018-.874-.075-1.275-.262-.402-.187-.773-.47-1.104-.806-.32-.325-.586-.685-.757-1.073-.17-.384-.245-.81-.205-1.215.04-.403.198-.805.44-1.153s.584-.664.957-.99L19.818 7.894c.503-.44-.157-1.195-.66-.755L4.764 19.76c-.38.333-.788.698-1.117 1.172-.33.475-.557 1.032-.616 1.626-.058.593.052 1.184.286 1.715.233.53.58.983.962 1.372.396.402.858.76 1.393 1.01.54.252 1.138.384 1.742.358.606-.026 1.185-.21 1.698-.496.513-.286.932-.657 1.32-1.007L27.34 10.33c.553-.496 1.128-1.022 1.602-1.67.475-.65.83-1.4.98-2.213.15-.81.088-1.65-.16-2.43-.25-.78-.676-1.48-1.203-2.082-.528-.6-1.172-1.117-1.915-1.463-.744-.347-1.573-.513-2.4-.464z'/%3E%3C/svg%3E") center no-repeat;
				background-size: 100%;
				transform: translate(-100%,-50%) scale(-1,1);
			}
			&:hover {
				background:#ccc;
				color:#fff;
			}
		}
	}

	.ui-selectmenu-button.ui-button {
		width: 100%;
		display: block;
		padding-right: 55px;
		background:url('../img/arrow_down.png') right center no-repeat #fff;
		background-size: auto 100%;
		appearance: none;
		padding: 15px 20px;
		color: #000 !important;
	}
	.ui-widget-content .ui-state-active {
		background:#f3f4f4;
		border:1px solid #f3f4f4;
		color: #000;
	}
	.ui-menu .ui-menu-item-wrapper {
		padding: 15px 20px;
	}
	.btn {
		display: block;
		text-align: center;
		padding: 10px;
		max-width: 215px;
		width: 100%;
		margin: 0 auto;
		border:1px solid transparent;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		font-family: 'Montserrat', sans-serif;
		font-weight: 700;
		&-red {
			background: var(--red);
			color: #fff;
			&:hover {
				background:#fff;
				border-color: var(--red);
				color: var(--red);
			}
		}
		&-bordered {
			background: #fbfbfb;
			border-color:#cccccc;
			color:#666666;
			&:hover {
				background:#ccc;
				color: #fff;
			}
		}
		&-notify {
			border: none;
			background: none;
			border-bottom: 1px dashed;
			text-align: center;
			width: 100%;
			font-size: 17px;
			color:var(--red);
			display: inline-block;
			width: auto;
			&-sended {
				color: #7e957b;
			}
			&:hover {
				border-color:transparent;
			}
		}
		&[disabled] {
			pointer-events:none;
			background: #ccc;
		}
	}	
	.to-cart {
		font-size: 14px;
		background: #fbfbfb;
		color: #333;
		font-weight: 700;
		&:hover {
			background:var(--red);
			color:#fff;
			border-color: var(--red)
		}
	}	
// login

// success
	.success_wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding:10px 50px 30px;
		background-color: rgb(255, 255, 255);
		box-shadow: 0px 1px 6.51px 0.49px rgba(0, 0, 0, 0.06);		
		width: 100%;
		max-width: 806px;
		margin: 0 auto 95px;
		text-align: center;
		h1 {
			font-weight: 900;
			font-size: 24px;
			margin: 5px auto 10px;
		}
		.order_info {
			margin: 15px auto 40px;
			strong {
				font-weight: 900;
				display: inline-block;
			}
		}
		.btn {
			color: #fff;
			max-width: 220px;
			width: 100%;
			font-size: 13px;
			&:hover {
				color: var(--red);
			}
		}
	}
// success 


@media screen and (max-width: 1200px) {
	.center {
		padding-left: calc(50vw - 360px);
		padding-right: calc(50vw - 360px);
	}
}

@media screen and (max-width: 767px) {
	.center {
		padding-left: calc(50vw - 250px);
		padding-right: calc(50vw - 250px);
	}
}
@media screen and (max-width: 515px) {
	.center {
		padding-left: calc(50vw - 185px);
		padding-right: calc(50vw - 185px);
	}
	.success_wrap {
		padding: 20px;
		max-width: 300px;
		font-size: 14px;
		h1 {
			font-size: 20px;
		}
		svg {
			width: 150px;
			height: 150px;
		}
	}
	.input {
		padding: 10px;
		width: 100%;
		font-size: 12px;
		&_label {
			margin-bottom: 10px;
			min-width: 80px;
			font-size: 12px;
		}
		&_text,
		&_error {
			padding-left: 0px;
			font-size: 10px;
		}
	}
	.login_form {
		p {
			padding-left: 0;
		}
	}
}
@media screen and (max-width: 400px) {
	.center {
		padding-left: calc(50vw - 170px);
		padding-right: calc(50vw - 170px);
	}
	.login_form {
		padding: 25px 10px;
	}
	.login_heading {
		font-size: 20px;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 355px) {
	.center {
		padding-left: calc(50vw - 150px);
		padding-right: calc(50vw - 150px);
	}
}
