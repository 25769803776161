.basket_header {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;
	h2 {
		width: 100%;
		margin-bottom: 30px;
		font-size: 24px;
		font-weight: 500;
	}
	a {
		color:var(--red);
		font-size: 15px;
		&:hover {
			text-decoration: dashed underline;
		}
	}
}

.table-basket {
	margin-bottom: 20px;
	margin-bottom: 56px;
	a {color:#000;}
	.table_row {
		grid-template-columns: 4fr repeat(4, 1fr)
	}
	.table_cell {
		&-price,
		&-cost {
			text-align: center;
		}
	}
	.input {
		max-width: 80px;
		margin: 0 auto;
	}
}

.table_footer {
	padding: 20px;
	text-align: right;
	border-bottom: 2px solid #ccc;
	.discount {
		margin-bottom: 15px;
		font-size: 18px;
	}
	.summary {
		font-size: 20px;
		font-weight: 500;
	}
}

.order_form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 30px 100px;
	margin-bottom: 90px;
	.input {
		width: 100%;

		&_label {
			margin-bottom: 20px;
			display: block;
			font-size: 17px;
			width: 100%;
		}
	}
	.wide {
		grid-column: 1 / 3;
	}
	.checkbox_wrap {
		display: flex;
		flex-wrap: wrap;
		.checkbox {
			margin:5px 30px 5px 0;
			&_label {
				font-weight: 400;
			}
		}
	}
	.btn {
		max-width: 286px;
	}
}

@media screen and (max-width: 1200px) {
	.table-basket {
		.table_row {
			grid-template-columns: 2fr repeat(4, 1fr)
		}
	}
}

@media screen and (max-width: 767px) {
	.table-basket {
		.table_row {
			grid-template-columns: 1fr;
			margin-bottom: 20px;
			border-bottom: 1px solid #ccc;
		}
		.table_header {
			display: none;
		}
		.table_cell {
			padding-top: 25px;
			position: relative;
			justify-content: flex-start;
			align-items: flex-start;
			text-align: left;
			&:before {
				content:attr(title);
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				text-align: left;
				display: block;
				color:var(--red);
				font-size: 12px;
				border-bottom: 1px dashed var(--red);
				padding-bottom: 4px;
			}
			.price_new,
			.price_old {
				text-align: left;
			}
		}
		.input {
			max-width: 100%;
			margin: 0 auto;
		}
	}
	.order_form {
		grid-gap: 30px;
	}
}

@media screen and (max-width: 515px) {
	.order_form {
		grid-template-columns: 1fr;
		.wide {
			grid-column: 1 / 2;
		}
	}
}