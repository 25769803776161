.dropdown {
	summary {
		position: relative;
		background:#666666;
		color: #fff;
		font-size: 18px;
		font-weight: 700;
		min-height: 50px;
		display: flex;
		align-items: center;
		padding: 16px 53px 12px;
		text-transform: uppercase;
		letter-spacing: .1em;
		cursor: pointer;
		border:1px solid #cccccc;
		&::-webkit-details-marker {
		    display: none;		    		
		}
		&:before {
			content:"";
			display: block;
			width: 10px;
			height: 20px;
			position: absolute;
			top:50%;
			left: 30px;
			transform: translate(0,-50%);
			background: url('../img/dropdown_arrow.png') center no-repeat;
		}
	}
	&.new summary {
		background:var(--red);
		pointer-events:none;
		&:before {
			display: none;
		}
	}
	&[open] > summary:before {
		transform: translate(0,-50%) rotate(90deg);
	}
	&-inner {
		summary {
			background:#eae8e8;
			color: #000;
			text-transform: none;
			letter-spacing:unset;
			font-size: 18px;
			&:before {
				background:url('../img/dropdown_arrow-gray.png') center no-repeat;
			}
		}

		&[open] {
			summary {
				background:#f5f5f5;
				font-weight: 500;
			}
		}
	}
}
.lower_price {
	border: none;
	background:none;
	margin-top: 7px;
	svg {
		pointer-events: none;
		transition: .5s;

	}
	&:hover {
		svg path{fill:var(--red);}
	}
}
.table-cart {
	display: grid;
	grid-template-columns: repeat(5, auto);
	padding:10px 50px;
	background: #333;
	grid-gap: 20px;
	color: #fff;
	align-items: center;
	justify-content: space-between;
	font-size: 20px;
	position: fixed;
	left:50%;
	bottom: 0;
	transform: translate(-50%,0);
	width: 100%;
	max-width: 1173px;
	z-index: 99;
	.btn {
		max-width: 280px;
		width: 100vw;
		padding: 4px;
	}
	p:nth-of-type(odd) {
		font-weight: 500;
	}
}

.wrapper-cart {
	padding-bottom: 45px;
}
@media screen and (max-width: 1200px) {
	.table-cart {
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;
		p:nth-of-type(even) {
			text-align: right;
		}
		.btn {
			grid-column: 1 / 3;
		}
	}
}


@media screen and (max-width: 515px) {
	.wrapper-cart {
		padding-bottom: 230px;
	}
	.table-cart {
		grid-template-columns: 1fr;
		padding: 15px;
		p {
			font-size: 12px;
			&:nth-of-type(even) {
				text-align: left;
				border-bottom: 1px dashed var(--red);
				padding-bottom: 10px;
			}
		}
		.btn {
			grid-column: 1 / 2;
		}
	}
}