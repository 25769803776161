.search {
	&_wrap {
		display: flex;
		align-items: flex-wrap;
		margin-bottom: 45px;
	}
	&_form {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.input_wrap {
			width: 100%;
			.input {
				background: url('../img/search.png') 20px center no-repeat #fff;
				padding-left: 60px;
				font-size: 16px;
				margin-bottom: 20px;
				width: 100%
			}
		}
	}
	&_btns {
		display: flex;
		.btn {
			margin-left: 26px;
			min-width: 174px;
			height: 47px;
			font-size: 14px;
			&-bordered {
				font-weight: 500;
			}
		}
	}
}
.filters_wrap {
	display: flex;
	justify-content: flex-start;
	width: calc(100% - 400px);
	.checkbox {
		margin-right: 15px;
	}
}
.checkbox {
	display: block;
	cursor: pointer;
	
	&_label {
		display: block;
		position: relative;
		padding-left: 30px;
		font-size: 16px;
		font-weight: 500;
		&:before {
			content:'';
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			background:#fff center no-repeat;
			border:1px solid #ccc;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate(0,-50%);
		}
	}
	input:checked + &_label:before {
		background-image: url('../img/check.png');

	}
}
.download_links {
	margin-left: 26px;
	position: relative;
	
	.download_toggler {
		display: block;
		white-space:nowrap;
		padding: 10px 0px 5px 0px;
		margin-left: 30px;
		position: relative;
		background: none;
		border: none;
		border-bottom: 1px dashed var(--red);
		margin-top: -10px;
		margin-bottom: 10px;
		&:hover {
			border-bottom: 1px dashed transparent;			
		}
		&:before {
			content:'';
			display: block;
			position: absolute;
			left: -30px;
			top:50%;
			transform: translate(0,-20%);
			width: 20px;
			height: 20px;
			background: url('../img/download.png') center no-repeat;
		}
		&.active + .download_form {
			display: block;
		}
	}
}
.download_form {
	right: 0;
	position: absolute;
	z-index: 99;
	padding: 30px 30px 35px 30px;
	background:#fff;
  	box-shadow: 0px 5px 19.92px 4.08px rgba(0, 0, 0, 0.06);
  	width: 100vw;
  	max-width: 260px;
  	display: none;
  	
  	.input_wrap {
  		margin-bottom: 30px;
  	}
	.close {
		position: absolute;
		top: 5px;
		right: 5px;
		color: #000;

	}
}
.table {
	&_row {
		background: #fff;
		border: 1px solid #ccc;
		border-bottom: none;
		display: grid;
		padding: 6px 35px 6px 23px;
		align-items: center;
		grid-gap: 10px;
		min-height: 66px;
		&:last-of-type {
			border-bottom: 1px solid #ccc;
		}
		&.table_header {
			border: none;
			background: none;
		}
	}
	&_cell {
		&-name {
			display: flex;
			align-items: center;
			img {
				margin-right: 15px;
			}
			.show_all {
				margin-left: 35px;
			}
			a {
				img {
					width: 100%;
					max-width: 53px;
					height: 53px;
					object-fit: cover;
				}
			}
		}
		&-sale,
		&-hit {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			text-align: center;
			color:#fff;
			font-size: 9px;
			font-weight: 700;
		}
		&-sale {
			background: #bf252b;
		}
		&-hit {
			background: #078e17;
		}
		&-number {
			text-align: center;
		}
		&-amount {
			text-align: center;
			
		}
		.input {
			padding:5px 10px;
			text-align: center;
			width: 100%;
		}
		&-wait {
			grid-column: 4 / 7;
			text-align: center;
			font-size: 16px;
		}
	}
}
.price {
	&_old {
		font-size: 14px;
		text-decoration: line-through;
		text-align: center;
		margin-bottom: 7px;
	}
	&_new {
		font-weight: 500;
		text-align: center;
	}
}
.table-search {
	margin-bottom: 80px;
	.table_row {
		grid-template-columns: 1fr minmax(25px , auto) minmax(25px , auto) minmax(115px,auto) 80px minmax(115px,auto) minmax(36px , auto);
	}
}
.show_all {
	color: var(--red);
	background: none;
	border: none;
	border-bottom: 1px dashed;
	&:hover {
		border-bottom: 1px dashed transparent;
	}
}


@media screen and (max-width: 1200px) {
	.filters_wrap {
		width: 100%;
		margin-bottom: 20px;
	}
	.search_btns {
		justify-content: space-around;
		width: 100%;
		.btn {
			margin:0;
		}
	}
	.table-search {
		.table_row {
			grid-template-columns: repeat(3, minmax(25px,1fr));
			align-items: flex-start;
			justify-content: center;
			grid-gap: 10px;
			&.table_header {
				display: none;
			}

		}
		.table_cell {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			position: relative;
			order: 1;
			&-name,
			// &:last-of-type,
			&-wait,
			&-price,
			&-amount,
			&-number,
			&-notify {
				order:99;
				padding-top: 20px;
			
			}
			&-name {
				justify-content: flex-start;
				grid-column: 1 / 4;
			}

			&-wait,
			&-notify {
				grid-column: 1 / 4;
				text-align: center;
			}
			&-amount {
				text-align: left;
			}
			&-price {
				flex-direction: column;
			}
			&:before {
				content:attr(title);
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				text-align: center;
				width: 100%;
				font-size: 12px;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	
	.search_wrap {
		flex-wrap: wrap;
	}
	.download_links {
		display: flex;
		margin:10px 0;
		justify-content: space-between;
		width: 100%;
		.download_toggler {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}

@media screen and (max-width: 515px) {
	.filters_wrap {
		flex-wrap: wrap;
		.checkbox {
			width: 50%;
			margin: 10px 0;
		}
	}
	.download_links {
		flex-wrap: wrap;
	}
	.table-search {
		.table_row {
			grid-template-columns: repeat(3, minmax(25px,1fr));
			margin-bottom: 20px;
			border-bottom: 1px solid #ccc;
			padding: 10px;
		}
		.table_cell {
			padding-top: 0;
			&-name,
			// &:last-of-type,
			&-wait,
			&-price,
			&-amount,
			&-number,
			&-notify  {
				grid-column: 1 / 4;
				padding-top: 30px;
				justify-content: flex-start;
				align-items: flex-start;
				&:before {
					text-align: left;
					padding-bottom: 5px;
					border-bottom: 1px dashed var(--red);
					color: var(--red);
				}
			}
			&-notify  {
				padding-bottom:20px;
			}
			.input_wrap {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 400px) {
	.search_btns {
		justify-content: space-around;
		width: 100%;
		.btn {
			width: 50%;
			min-width: 0;
		}
	}
}