/*fonts*/
	@font-face {
		font-family: 'Graphik';
		src: url('../fonts/graphik-lc-web/GraphikLCWeb-Regular.woff2') format('woff2'), /* Super Modern Browsers */
			 url('../fonts/graphik-lc-web/GraphikLCWeb-Regular.woff') format('woff'), /* Pretty Modern Browsers */
			 url('../fonts/graphik-lc-web/GraphikLCWeb-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
		font-weight: 400;
		
	}
	@font-face {
		font-family: 'Graphik';
		src: url('../fonts/graphik-lc-web/GraphikLCWeb-Medium.woff2') format('woff2'), /* Super Modern Browsers */
			 url('../fonts/graphik-lc-web/GraphikLCWeb-Medium.woff') format('woff'), /* Pretty Modern Browsers */
			 url('../fonts/graphik-lc-web/GraphikLCWeb-Medium.ttf')  format('truetype'); /* Safari, Android, iOS */
		font-weight: 500;
	}
	@font-face {
		font-family: 'Graphik';
		src: url('../fonts/graphik-lc-web/GraphikLCWeb-Bold.woff2') format('woff2'), /* Super Modern Browsers */
			 url('../fonts/graphik-lc-web/GraphikLCWeb-Bold.woff') format('woff'), /* Pretty Modern Browsers */
			 url('../fonts/graphik-lc-web/GraphikLCWeb-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
		font-weight: 700;
	}
	@font-face {
		font-family: 'Graphik';
		src: url('../fonts/graphik-lc-web/GraphikLCWeb-Black.woff2') format('woff2'), /* Super Modern Browsers */
			 url('../fonts/graphik-lc-web/GraphikLCWeb-Black.woff') format('woff'), /* Pretty Modern Browsers */
			 url('../fonts/graphik-lc-web/GraphikLCWeb-Black.ttf')  format('truetype'); /* Safari, Android, iOS */
		font-weight: 900;
		
	}

	@font-face {
		font-family: 'Montserrat';
		src: url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2'), /* Super Modern Browsers */
			 url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'), /* Pretty Modern Browsers */
			 url('../fonts/montserrat/Montserrat-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
		font-weight: 400;
	}
	@font-face {
		font-family: 'Montserrat';
		src: url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2'), /* Super Modern Browsers */
			 url('../fonts/montserrat/Montserrat-Bold.woff') format('woff'), /* Pretty Modern Browsers */
			 url('../fonts/montserrat/Montserrat-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
		font-weight: 700;
	}
/*fonts*/